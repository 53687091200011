<template>
  <div>
    <PageTitlebar title="Inventory Source Import History">
      <template v-slot:top>
        <Link
          :to="{
            name: 'AdvertiserAccountsOverview',
            query: { advertiser_account_id: advertiserAccountId }
          }"
          label="Back to Sources"
          icon="sym_r_chevron_left"
        />
      </template>

      <template v-slot:actions>
        <DateRangeSelector
          :initial-start-date="startDate"
          :initial-end-date="endDate"
          initial-preset="This Month"
          @update="
            dates => {
              updateDateRange(dates);
              fetchImportHistory();
            }
          "
        />
      </template>

      <template v-slot:bottom>
        <div v-if="!loading" class="flex items-center">
          <StatusBadge
            :status="
              source.active == 0
                ? 'deactivated'
                : source.paused == 1
                ? 'paused'
                : 'active'
            "
            icon-only
            class="q-mr-sm"
            icon-only-size="24px"
          />
          <div>
            <p>{{ source.name }}</p>
            <div class="flex items-center">
              <Label :text="source.id" />
              <q-badge
                color="gray-light"
                text-color="gray-medium-dark"
                :label="'Account ID ' + source.advertiser_account_id"
                class="q-ml-sm"
              />
            </div>
          </div>
        </div>
      </template>
    </PageTitlebar>

    <DataTable
      table-key="inventorySourceImportHistory"
      row-key="id"
      :columns="columns"
      :rows="importHistory"
      :loading="loading"
      :downloadable="true"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <!--<template v-slot:title>
        <ActionsDropdownButton
          v-if="!loading"
          :flat="false"
          custom-color="primary"
          :actions="getSourceActionsList(source)"
        />
      </template>-->
      <template v-slot:status="row">
        <div class="column items-start">
          <StatusBadge
            :status="row.statusDetails.badge"
            :custom-label="row.statusDetails.label"
            include-icon
          />
        </div>
      </template>
      <template v-slot:results="row">
        <div v-if="row.failed == 1">
          <small class="block">
            <b>{{ row.failed_reason }}</b>
          </small>
          <small class="block" style="margin-top: -2px">
            <a href="javascript:void(0)" @click="promptImportDetails(row)"
              >View Details</a
            >
          </small>
        </div>
        <div v-else-if="row.time_completed.length == 0">
          -
        </div>
        <div v-else>
          <div>
            <strong>
              {{ numberFormatter(parseInt(row.products_active_total)) }}
            </strong>
            {{ $store.state.productTypeConfigs.type }}s imported
          </div>
          <div class="text-font-secondary">
            <strong>
              {{ numberFormatter(parseInt(row.feed_total)) }}
            </strong>
            records found
          </div>
          <div class="text-font-secondary">
            <strong>{{
              Intl.NumberFormat("en-US").format(parseInt(row.products_skipped))
            }}</strong>
            records skipped due to issues
          </div>
          <small class="block">
            <a href="javascript:void(0)" @click="promptImportDetails(row)"
              >View Details</a
            >
          </small>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import DataTable from "@/components/UI/DataTable";
import Link from "@/components/UI/Link.vue";
import StatusBadge from "@/components/UI/StatusBadge.vue";
import Label from "@/components/UI/Label.vue";
import { Dialog } from "quasar";
import ConfirmationModal from "@/components/UI/ConfirmationModal.vue";
import DateRangeSelector from "@/components/UI/DateRangeSelector.vue";
import InventorySourcesMixin from "@/mixins/Advertiser/InventorySourcesMixin";
// import ActionsDropdownButton from "@/components/UI/ActionsDropdownButton.vue";

export default {
  name: "InventorySourceImportHistory",
  mixins: [InventorySourcesMixin],
  props: {
    advertiserAccountId: {
      type: [String, Number],
      required: true
    },
    inventorySourceId: {
      type: [String, Number],
      required: true
    }
  },
  components: {
    // ActionsDropdownButton,
    DateRangeSelector,
    Label,
    StatusBadge,
    Link,
    DataTable,
    PageTitlebar
  },
  data() {
    return {
      loading: true,
      source: [],
      importHistory: [],
      startDate: this.$dayjs()
        .tz()
        .format("YYYY-MM-01 HH:mm:ss"),
      endDate: this.$dayjs()
        .tz()
        .format("YYYY-MM-DD 23:59:59"),
      columns: [
        {
          name: "id",
          label: "Import ID",
          field: "id",
          type: "dimension",
          sortable: true
        },
        {
          name: "status",
          label: "Status",
          field: "status",
          hideField: true,
          type: "dimension",
          sortable: true
        },
        {
          name: "time_created",
          label: "Time Started",
          field: row =>
            row.time_created
              ? this.convertUtcToBusinessTimezone(row.time_created, "lll")
              : "-",
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          type: "metric",
          metricType: "date",
          align: "left"
        },
        {
          name: "time_completed",
          label: "Time Completed",
          field: row =>
            row.time_completed
              ? this.convertUtcToBusinessTimezone(row.time_completed, "lll")
              : "-",
          sortable: true,
          sort: (a, b) => this.sortDate(a, b),
          type: "metric",
          metricType: "date",
          align: "left"
        },
        {
          name: "results",
          label: "Results",
          field: "",
          hideField: true,
          sortable: false,
          filterable: false
        },
        {
          name: "feed_total",
          label: "Records Found",
          field: "feed_total",
          hideField: true,
          sortable: false,
          filterable: true,
          type: "metric",
          metricType: "number",
          visible: false
        },
        {
          name: "products_skipped",
          label: "Records Skipped",
          field: "products_skipped",
          hideField: true,
          sortable: false,
          filterable: true,
          type: "metric",
          metricType: "number",
          visible: false
        },
        {
          name: "products_active_total",
          label:
            this.$store.state.productTypeConfigs.typeFormatted + "s Imported",
          field: "products_active_total",
          hideField: true,
          sortable: false,
          filterable: true,
          type: "metric",
          metricType: "number",
          visible: false
        },
        {
          name: "products_created",
          label:
            this.$store.state.productTypeConfigs.typeFormatted + "s Created",
          field: "products_created",
          hideField: true,
          sortable: false,
          filterable: true,
          type: "metric",
          metricType: "number",
          visible: false
        },
        {
          name: "products_updated",
          label:
            this.$store.state.productTypeConfigs.typeFormatted + "s Updated",
          field: "products_updated",
          hideField: true,
          sortable: false,
          filterable: true,
          type: "metric",
          metricType: "number",
          visible: false
        },
        {
          name: "products_deactivated",
          label: this.$store.state.productTypeConfigs.type + "s Removed",
          field: "products_deactivated",
          hideField: true,
          sortable: false,
          filterable: true,
          type: "metric",
          metricType: "number",
          visible: false
        }
      ]
    };
  },
  computed: {},
  mounted() {
    this.populateInventorySourceImportHistory();
  },
  methods: {
    populateInventorySourceImportHistory() {
      this.$store
        .dispatch("inventorySources/GET_REQUEST", {
          params: {
            advertiser_account_id: this.advertiserAccountId,
            id: this.inventorySourceId
          }
        })
        .then(data => {
          this.source = data[0];
          this.fetchImportHistory();
        });
    },
    fetchImportHistory() {
      this.loading = true;

      this.$store
        .dispatch("inventorySources/GET_IMPORT_HISTORY_REQUEST", {
          params: {
            id: this.inventorySourceId,
            start_date: this.startDate,
            end_date: this.endDate
          }
        })
        .then(data => {
          this.importHistory = data.map(row => {
            row.statusDetails = {
              color: "",
              icon: "",
              message: ""
            };
            row.status = "";

            if (row.time_completed.length == 0 && row.failed == 0) {
              row.status = "Running Import";
              row.statusDetails.badge = "running";
              row.statusDetails.label = "Import Running...";
            } else if (
              row.failed == 1 &&
              row.failed_reason == "Unknown Error Occured"
            ) {
              row.status = "Unknown Error Occurred";
              row.statusDetails.badge = "error";
              row.statusDetails.label = "Unknown Error Occurred";
            } else if (row.failed == 1) {
              row.status = "Failed";
              row.statusDetails.badge = "error";
              row.statusDetails.label = "Failed";
            } else if (row.products_skipped > 0) {
              row.status = "Successful - With Issues";
              row.statusDetails.badge = "success";
              row.statusDetails.label = "Successful - With Issues";
            } else {
              row.status = "Successful";
              row.statusDetails.badge = "success";
              row.statusDetails.label = "Successful";
            }

            return row;
          });

          this.loading = false;
        });
    },
    updateDateRange(dates = null) {
      if (dates) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
      }
    },
    promptImportDetails(importData) {
      let message = "";

      if (importData.failed == 1) {
        message = "<b>" + importData.failed_reason + "</b><br />";
        message += importData.failed_metadata.message;
      } else {
        message =
          "<div class='q-mb-md'>If issues were encountered, please review your inventory source and ensure the " +
          this.$store.state.productTypeConfigs.type +
          "s inside your file are valid.</div>";

        if (importData.failed == 0 && importData.time_completed.length > 0) {
          let metrics = [
            {
              label: "records found",
              value: this.numberFormatter(parseInt(importData.feed_total)),
              secondary: false
            },
            {
              label: this.$store.state.productTypeConfigs.type + "s imported",
              value: this.numberFormatter(
                parseInt(importData.products_active_total)
              ),
              secondary: false
            },
            {
              label: this.$store.state.productTypeConfigs.type + "s created",
              value: Intl.NumberFormat("en-US").format(
                parseInt(importData.products_created)
              ),
              secondary: true
            },
            {
              label: this.$store.state.productTypeConfigs.type + "s updated",
              value: Intl.NumberFormat("en-US").format(
                parseInt(importData.products_updated)
              ),
              secondary: true
            },
            {
              label: this.$store.state.productTypeConfigs.type + "s removed",
              value: Intl.NumberFormat("en-US").format(
                parseInt(importData.products_deactivated)
              ),
              secondary: true
            },
            {
              label: "records skipped due to issues",
              value: Intl.NumberFormat("en-US").format(
                parseInt(importData.products_skipped)
              ),
              secondary: false
            }
          ];
          metrics.forEach(metric => {
            if (metric.secondary) {
              message +=
                "<div class='text-font-secondary'><span class='text-weight-medium'>" +
                metric.value +
                "</span> " +
                metric.label +
                "</div>";
            } else {
              message +=
                "<div class='q-mt-sm q-mr-md text-weight-medium'><strong class='text-weight-bolder'>" +
                metric.value +
                "</strong> " +
                metric.label +
                ".</div>";
            }
          });

          if (
            "analysis_log_data" in importData &&
            "enhanced_analysis_counts" in importData.analysis_log_data
          ) {
            Object.keys(
              importData.analysis_log_data.enhanced_analysis_counts
            ).forEach(analysisKey => {
              let cleanedValue = Intl.NumberFormat("en-US").format(
                parseInt(
                  importData.analysis_log_data.enhanced_analysis_counts[
                    analysisKey
                  ]
                )
              );
              let cleanedLabel =
                "with " +
                (analysisKey == "product_creation_failed"
                  ? "unknown issues"
                  : analysisKey.replaceAll("_", " "));
              if (cleanedValue == 0) return;
              message +=
                "<div class='text-font-secondary'><span class='text-weight-medium'>" +
                cleanedValue +
                "</span>" +
                " " +
                cleanedLabel +
                "</div>";
            });
          }
        }
      }

      Dialog.create({
        component: ConfirmationModal,
        componentProps: {
          header: "Import Details",
          subHeader: "Import " + importData.id,
          message: message,
          hidePrimaryButton: true,
          secondaryButtonLabel: "Close"
        }
      }).onOk(dialog => {
        dialog.hide();
      });
    },
    getSourceActionsList(source) {
      let actionsList = [
        {
          action: () =>
            this.$router.push({
              name: "InventorySourceEditor",
              params: {
                advertiserAccountId: source.advertiser_account_id,
                inventorySourceId: source.id
              }
            }),
          label: "Edit Source",
          icon: "sym_r_edit"
        }
      ];

      if (source.active == 1) {
        if (source.source_type === "feed") {
          actionsList.push({
            action: () =>
              this.promptRunSourceManualImport(source).then(() =>
                this.populateInventorySourceImportHistory()
              ),
            label: "Run Import",
            icon: "sym_r_sync",
            disabled: source.import_status.length > 0,
            disabledTooltip: "Disabled while import is running."
          });

          if (source.paused == 0) {
            actionsList.push({
              action: () =>
                this.promptPauseSource(source).then(() =>
                  this.populateInventorySourceImportHistory()
                ),
              label: "Pause Imports",
              icon: "sym_r_pause",
              disabled: source.import_status.length > 0,
              disabledTooltip: "Disabled while import is running."
            });
          } else {
            actionsList.push({
              action: () =>
                this.promptResumeSource(source).then(() =>
                  this.populateInventorySourceImportHistory()
                ),
              label: "Resume Imports",
              icon: "sym_r_play_arrow"
            });
          }
        } else {
          actionsList.push({
            action: () =>
              this.$router.push({
                name: "InventorySourceEditor",
                params: {
                  advertiserAccountId: source.advertiser_account_id,
                  inventorySourceId: source.id
                },
                query: {
                  manual_upload_initial: 1
                }
              }),
            label: "Upload New File",
            icon: "sym_r_upload_file",
            disabled: source.import_status.length > 0,
            disabledTooltip: "Disabled while import is running."
          });
        }

        actionsList.push({
          action: () =>
            this.promptDeactivateSource(source).then(() =>
              this.populateInventorySourceImportHistory()
            ),
          label: "Deactivate Source",
          icon: "sym_r_stop_circle",
          disabled: source.import_status.length > 0,
          disabledTooltip: "Disabled while import is running."
        });
      } else {
        actionsList.push({
          action: () =>
            this.promptActivateSource(source).then(() =>
              this.populateInventorySourceImportHistory()
            ),
          label: "Activate Source",
          icon: "sym_r_play_circle"
        });
        actionsList.push({
          action: () =>
            this.promptDeleteSource(source).then(() =>
              this.$router.push({
                name: "AdvertiserAccountsOverview",
                query: { advertiser_account_id: source.advertiser_account_id }
              })
            ),
          label: "Delete Source",
          icon: "sym_r_delete"
        });
      }

      return actionsList;
    }
  }
};
</script>

<style scoped lang="scss"></style>
